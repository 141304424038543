import(/* webpackMode: "eager" */ "/app/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2Fcomponents%2Forganic-header%2Findex.css.ts.vanilla.css%22%2C%22source%22%3A%22Lm00cGczMDAgewogIHBvc2l0aW9uOiBzdGlja3k7CiAgdG9wOiAwOwogIHdpZHRoOiAxMDAlOwogIHotaW5kZXg6IDUwOwp9Ci5tNHBnMzAxIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXg6IDEgMSAwJTsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9%22%7D");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/packages/apps-bar/src/android.svg");
;
import(/* webpackMode: "eager" */ "/app/packages/apps-bar/src/ibooks.svg");
;
import(/* webpackMode: "eager" */ "/app/packages/apps-bar/src/ios.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/footer/src/seo-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/layouts/src/header-height-observer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/navbar/src/navbar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/organic/app/components/organic-header/index.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/packages/server/src/user/context.tsx");
